html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.MuiInputBase-root,
.MuiTypography-body1{
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.MuiTooltip-tooltip{
  font-size: 14px!important;
  background-color: #ffdede!important;
  color: #780000!important;
  padding: 5px 10px;
}
.MuiTooltip-tooltip p {
  margin: 0;
}

#tooltip-container {
  position: absolute;
  top: 0;
  left: 0;
}

.cke_source {
  white-space: pre-wrap !important;
  font-size: 16px !important;
}

.ag-theme-balham .ag-cell {
  font-size: 16px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.ag-theme-balham .ag-selection-checkbox span,
.ag-theme-balham .ag-group-expanded span,
.ag-theme-balham .ag-group-contracted span {
  margin-right: 0;
}

.ag-theme-balham .ag-ltr .ag-row-group-leaf-indent {
  margin-left: 18px;
}

.ag-theme-balham .ag-ltr .ag-row-group-indent-1 {
  padding-left: 18px;
}

.ag-theme-balham .ag-ltr .ag-row-group-indent-2 {
  padding-left: 36px;
}

.ag-theme-balham .ag-ltr .ag-row-group-indent-3 {
  padding-left: 54px;
}

.ag-theme-balham .ag-ltr .ag-row-group-indent-4 {
  padding-left: 72px;
}

body .no-border-grid .ag-root,
body .no-border-grid .ag-header,
body .no-border-grid .ag-header,
body .no-border-grid .ag-status-bar {
  border: none;
}

.no-status-bar-grid .ag-status-bar {
  display: none;
}

.black {
  color: black;
}
.grey {
  color: grey;
}
.lightgrey {
  color: lightgrey;
}
.orange {
  color: orange;
}
.blue {
  color: dodgerblue;
}
.green {
  color: green;
}
.red {
  color: red;
}

.flag5 {
  color: #ff0e05;
}
.flag4 {
  color: #ccc300;
}
.flag3 {
  color: #fe05fa;
}
.flag2 {
  color: #1e27ff;
}
.flag1 {
  color: #4dc405;
}

target {
  color: #2087ff;
}

glossary{
  color: #2DA4AC;
}

panel, voc {
  color: #2087ff;
  text-decoration: underline;
}

.state0 {
  color: #808080;
}
.state1 {
  color: #d0011b;
}
.state2 {
  color: #f6a623;
}
.state3 {
  color: #4990e2;
}
.state4 {
  color: #7ed321;
}

.cke.no-toolbar .cke_top {
  border: 0;
}

.gridPanelLevel__indicator--L1 {
  color: #348e38;
}

.gridPanelLevel__indicator--L2 {
  color: #2b78e4;
}

.gridPanelLevel__indicator--L3 {
  color: #ff9900;
}

.gridPanelLevel__indicator--L4 {
  color: #cf2a27;
}

.gridPanelLevel__indicator--L5 {
  color: #ff00ff;
}

.error--on {
  color: red;
}

.article_color_m {
  color: dodgerblue;
}

.article_color_f {
  color: indianred;
}

.article_color_n {
  color: mediumseagreen;
}

.ag-watermark{
  display: none;
}

.underline{
  text-decoration: underline;
}

.conjugationReport--change{
  color: blue;
}

.conjugationReport--irregular{
  color: red;
}

.ck_table{
  margin:40px auto;
  text-align: center;
  color: #246732;
}
.ck_table td{
  padding: 8px;
  border: none!important;
}
.ck_table .ck_header{
  font-size: 14px;
  background-color: #A5DA4F;
  font-weight: bold;
}
.ck_table tr:nth-child(odd) .ck_cell{
  background-color: #F1F9E2;
}
.ck_table tr:nth-child(even) .ck_cell{
  background-color: #fffff5;
}

.ck_chart{
  margin:40px auto;
  text-align: center;
  color: #1CA8B7;
}
.ck_chart td{
  padding: 8px;
  border: none!important;
}
.ck_chart .ck_header{
  font-size: 14px;
  background-color: #1CA8B7;
  font-weight: bold;
  color: #FFF;
}
.ck_chart tr:nth-child(odd) {
  background-color: #EEFCFE;
}
.ck_chart tr:nth-child(even) {
  background-color: #fAffff;
}

.ck_conj{
  margin: 4px;
  text-align: center;
  color: #004E5C;
}
.ck_conj td{
  padding: 8px;
  border: none!important;
}
.ck_conj .ck_header{
  font-size: 14px;
  background-color: #fce895;
  font-weight: bold;
}
.ck_conj tr:nth-child(odd) .ck_cell{
  background-color: #FFFEEE;
}
.ck_conj tr:nth-child(even) .ck_cell{
  background-color: #FFF;
}

.ck_conj reg{
  color: #5fb841;
}

.ck_conj irreg {
  color: red;
}

.ck_conj change {
  color: blue;
}
.ck_table p,
.ck_chart p,
.ck_conj p {
  margin: 0;
}

SDLtarget{
  color: #1068ce;
}

SDLglossary{
  color: #2DA4AC;
}

SDLbold{
  color: #616161;
}

SDLem{
  color: #bd783d;
}

.non-printable-space {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfgBhcINw7hsvcvAAAAHWlUWHRDb21tZW50AAAAAABDcmVhdGVkIHdpdGggR0lNUGQuZQcAAAHGSURBVGjezZmrjsJQEIa/HhCYDQiyCQJPBaGGWlRtHwCNQ/MGSB6Bp4AXqaupQ2yyrICsIQFyVrAnwF4ChfbM+cfQIL4fZnouMx55VaGFT0CAT5sGsGFFSkJCyhtHSlOdmAX6RiyIqReN9gjvQF/bCPGKgkesc8FNrImeN9EjewhuIqP3OLzG7Cm4iRm1R/Ad9oXgNZo9nbz4uDC4iTgPflw4XqMZ34uflILXaCZyv/7ufyEuFX+zFjql4zX6+o3wrt77T6qUrwMv7MyDuvhiagUPVaZ/L7raYvR+bzmZVQPZz20qsorXaKLLIvR4p4ldffCKNkXYt46HJv3zw8J6Ak6npu8U1NkgowZbBQyQ0uBUAyMxAyPwqHBATlVFC0m1FL6oAV8RiBoIHDAgnoK2qIG2x97SMeSf05FCWEpsHzhpo1iJGlgpUlEDqSIRNZA4YEA4BQ5sx0eWYvglRwXMxQzMHTmUboWSsGRrPoYi94LQoauZZmg9AUO0U9dz8QYFUFBn+L7usZtNqh1dK+XXPeOda1Q60Kp1oFntQLu+nFqI89aq8MjmtC6IDq3MAi04tjNrpejg0pgQHN2eVcrwOr/Lgsf3X9YLTKrUayyTAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 2px 2px;
  opacity: 0.5;
}

.non-printable-newline::after {
  content: "↩";
  font-size: 0.7em;
  opacity: 0.5;
  position: absolute;
}
